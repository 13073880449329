import { useContext } from 'react';
import { ConfirmationPopupContext } from './ConfirmationPopupContext';
import { ConfirmationPopupContextProps } from './types';

export const useConfirmationPopup = (): ConfirmationPopupContextProps => {
  const context = useContext(ConfirmationPopupContext);
  if (!context) {
    throw new Error(
      'useConfirmationPopup must be used within a ConfirmationPopupProvider',
    );
  }
  return context;
};
