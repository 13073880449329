import { useCallback, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { Colors } from '../../theme/colors';
import AppButton from '../AppButton';
import {
  ConfirmationPopupContextProps,
  ConfirmationPopupProviderProps,
  IConfirmationPopupConfig,
} from './types';

import { ConfirmationPopupContext } from './ConfirmationPopupContext';

export const ConfirmationPopupProvider = ({
  children,
}: ConfirmationPopupProviderProps) => {
  const [popupConfig, setPopupConfig] =
    useState<IConfirmationPopupConfig | null>(null);
  const [confirmationPopupOpen, setConfirmationPopupOpen] = useState(false);

  const openConfirmationPopup = useCallback(
    (config: IConfirmationPopupConfig) => {
      setPopupConfig(config);
      setConfirmationPopupOpen(true);
    },
    [],
  );

  const closeConfirmationPopup = useCallback(() => {
    setPopupConfig(null);
    setConfirmationPopupOpen(false);
  }, []);

  const contextValue: ConfirmationPopupContextProps = {
    openConfirmationPopup,
  };

  return (
    <ConfirmationPopupContext.Provider value={contextValue}>
      {children}
      {popupConfig && (
        <Dialog
          open={confirmationPopupOpen}
          onClose={closeConfirmationPopup}
          PaperProps={{
            sx: {
              maxWidth: popupConfig.width ?? '400px',
              borderRadius: '8px',
            },
          }}>
          <DialogTitle
            style={{
              textDecoration: 'none',
              fontSize: '20px',
              fontWeight: 600,
              textAlign: 'left',
              color: Colors.fontColorPrimary,
              backgroundColor: Colors.backgroundPrimary,
              lineHeight: '25px',
            }}>
            {popupConfig.heading}
          </DialogTitle>
          <DialogContent
            sx={{
              backgroundColor: Colors.chipDefaultBackground,
            }}>
            <DialogContentText
              component="div"
              sx={{
                color: Colors.fontColorPrimary,
                marginTop: '20px',
                fontSize: '16px',
              }}>
              {popupConfig.content ?? popupConfig.message}
            </DialogContentText>
          </DialogContent>
          <DialogActions
            sx={{
              px: 3,
              py: 2,
              backgroundColor: Colors.chipDefaultBackground,
            }}>
            <AppButton
              onClick={closeConfirmationPopup}
              variant="contained"
              title={popupConfig.cancelButtonTitle ?? 'Cancel'}
              color="secondary"
            />
            <AppButton
              onClick={() => {
                popupConfig.onConfirm();
                closeConfirmationPopup();
              }}
              variant="contained"
              title={popupConfig.confirmButtonTitle ?? 'Confirm'}
              color={popupConfig.color ?? 'primary'}
            />
          </DialogActions>
        </Dialog>
      )}
    </ConfirmationPopupContext.Provider>
  );
};
