import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const ConfirmationMessage = () => {
  return (
    <Box>
      <Typography variant="body2" style={{ margin: '8px 0' }}>
        Another user is already registered on the system with the same email
        address.
      </Typography>
      <Typography variant="body2" style={{ margin: '8px 0' }}>
        Do you want to invite that user to your restaurant or choose a different
        email address?
      </Typography>
    </Box>
  );
};

export default ConfirmationMessage;
