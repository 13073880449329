import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import PinField from 'react-pin-field';
import { boolean, object, ObjectSchema, string } from 'yup';
import * as yup from 'yup';

import AppPhoneInput from '../../../components/AppPhoneInput';
import AppPopup, { IDialogAction } from '../../../components/AppPopup';
import AppSelector from '../../../components/AppSelector';
import { SNACKBARTYPE, useSnackbar } from '../../../components/AppSnackbar';
import AppTextInput from '../../../components/AppTextInput';
import { RESTAURANT_ROLES } from '../../../constants';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { getRoleName } from '../../../utils';
import YupPhone from '../../../utils/YupPhone';
import { EMPLOYEE_ACTION_KEYS } from '../constants';
import { createEmployee, fetchEmployeePin } from '../redux/employeeSlice';
import { CreateEmployeesRequestBodyDTO } from '../types/employees.types';

YupPhone(yup);

type CreateEmployeePopupProps = {
  open: boolean;
  onClose: (shouldRefresh?: boolean) => void;
};

type ICreateEmployeeForm = {
  name?: string;
  email?: string;
  role: string;
  backofficeAccess: boolean;
  phoneNumber?: string;
  posAccess?: boolean;
  pin: string[];
};

const validationSchema: ObjectSchema<ICreateEmployeeForm> = object({
  backofficeAccess: boolean().default(false),

  name: yup
    .string()
    .trim()
    .test({
      name: 'conditional-required',
      test: function (value: string | undefined) {
        // If backofficeAccess is false, then the name is required
        if (this.parent.backofficeAccess === false) {
          return !!value;
        }
        // Otherwise, the name is optional
        return true;
      },
      message: 'Please enter an employee name',
    })
    .max(60, 'Character limit exceeded (60)'),

  email: yup
    .string()
    .test({
      name: 'conditional-required-email',
      test: function (value: string | undefined) {
        if (this.parent.backofficeAccess) {
          if (!value) {
            return this.createError({
              message: 'Please enter an email address.',
            });
          }

          if (!/^.+@.+\..+$/.test(value)) {
            return this.createError({ message: 'Invalid email format' });
          }

          try {
            yup.string().email().validateSync(value);
          } catch {
            return this.createError({ message: 'Invalid email format' });
          }
        }
        return true;
      },
      message: 'Please enter an email address.',
    })
    .email('Invalid email format'),
  role: string()
    .oneOf(Object.values(RESTAURANT_ROLES))
    .required('Please select a role.'),
  phoneNumber: yup.string().when('backofficeAccess', {
    is: false,
    then: (schema) =>
      schema
        .phone(
          { strictValidation: true },
          'Please enter a valid mobile number after the country code',
        )
        .required('Phone number is required. Please fill the field'),
    otherwise: (schema) => schema.optional(),
  }),
  posAccess: boolean(),
  pin: yup
    .array()
    .of(
      yup
        .string()
        .matches(/^\d$/, 'PIN must be a number')
        .required('PIN is required'),
    )
    .required()
    .length(4, 'PIN must be exactly 4 digits'),
});

const CreateEmployeePopup = ({ open, onClose }: CreateEmployeePopupProps) => {
  const { openSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const { refreshing } = useAppSelector((state) => state.employees);
  const [pinError, setPinError] = useState('');
  const [showPinFields, setShowPinFields] = useState<boolean>(true);
  const [pin, setPin] = useState('');
  const pinFieldRef = useRef<HTMLInputElement[]>([]);

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    reset,
    setValue,
    watch,
  } = useForm<ICreateEmployeeForm>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: '',
      email: '',
      role: RESTAURANT_ROLES.ADMINISTRATOR,
      backofficeAccess: false,
      phoneNumber: '',
      posAccess: false,
      pin: ['', '', '', ''],
    },
  });

  const backofficeAccess = watch('backofficeAccess');

  useEffect(() => {
    const fetchPin = async () => {
      const actionResult = await dispatch(fetchEmployeePin());
      const pinValue = actionResult.payload as string;
      const formattedPin = pinValue.toString().padStart(4, '0');
      setPin(formattedPin);

      // Convert the fetched PIN string to an array for setting each input value
      const pinDigits = formattedPin.split('');

      //  set the value for each input element
      if (pinFieldRef.current && pinFieldRef.current.length === 4) {
        pinFieldRef.current.forEach((input, index) => {
          if (input) {
            input.value = pinDigits[index];
            // Also update the react-hook-form state
            setValue(`pin.${index}`, pinDigits[index], {
              shouldValidate: true,
            });
          }
        });
      }
    };

    if (open) {
      fetchPin();
    }
  }, [open, dispatch, setValue, showPinFields]);

  const onSubmit = handleSubmit(async (data) => {
    // Map form data to API's expected structure
    const employeeData: CreateEmployeesRequestBodyDTO = {
      isEnableBackOfficeAccess: data.backofficeAccess ?? false,
      isEnablePosAccess: data.posAccess ?? false,
      email: data.email ?? undefined,
      role: data.role,
      posPin: pin,

      ...(!data.backofficeAccess && {
        name: data.name ?? undefined,
        mobile: data.phoneNumber ?? undefined,
      }),
    };

    const actionResult = await dispatch(createEmployee(employeeData));

    if (actionResult.type.endsWith('fulfilled')) {
      openSnackbar(
        data.backofficeAccess
          ? 'Invitation sent successfully!'
          : 'Employee created successfully!',
        SNACKBARTYPE.SUCCESS,
      );
      onClose(true);
    } else {
      const apiError = actionResult.payload as { message?: string };
      openSnackbar(
        apiError?.message ?? 'Failed to create employee.',
        SNACKBARTYPE.ERROR,
      );
      setPinError(apiError?.message ?? '');
    }
  });

  useEffect(() => {
    if (!open) {
      setPinError('');
      reset();
    }
  }, [open, reset]);

  useEffect(() => {
    if (!backofficeAccess) {
      setValue('posAccess', true);
    }
  }, [backofficeAccess, setValue]);

  const actions: IDialogAction[] = [
    {
      key: EMPLOYEE_ACTION_KEYS.CANCEL,
      title: 'Cancel',
      onClick: () => {
        reset();
        onClose();
      },
      color: 'secondary',
    },
    {
      key: EMPLOYEE_ACTION_KEYS.SUBMIT,
      title: backofficeAccess ? 'Invite' : 'Create',
      onClick: onSubmit,
      disabled: isSubmitting,
      color: 'primary',
    },
  ];

  const handlePinChange = (code: string) => {
    // Split the code into an array of strings (one for each digit).
    const pinArray = code.split('');

    // Use setValue from react-hook-form to update the form state.
    setValue('pin', pinArray, { shouldValidate: true });
  };

  const handlePinComplete = (code: string) => {
    setPin(code);
  };

  return (
    <form onSubmit={onSubmit}>
      <AppPopup
        open={open}
        onClose={() => {}}
        loading={refreshing}
        actions={actions}
        fixedWidth="460px"
        title={'Create Employee'}>
        <Controller
          name="role"
          control={control}
          render={({ field: { onChange, value } }) => (
            <AppSelector
              label="Role"
              placeholder="Administrator"
              value={value}
              onChange={onChange}>
              <MenuItem value={RESTAURANT_ROLES.OWNER}>
                {getRoleName(RESTAURANT_ROLES.OWNER)}
              </MenuItem>
              <MenuItem value={RESTAURANT_ROLES.ADMINISTRATOR}>
                {getRoleName(RESTAURANT_ROLES.ADMINISTRATOR)}
              </MenuItem>
              <MenuItem value={RESTAURANT_ROLES.MANAGER}>
                {getRoleName(RESTAURANT_ROLES.MANAGER)}
              </MenuItem>
              <MenuItem value={RESTAURANT_ROLES.CASHIER}>
                {getRoleName(RESTAURANT_ROLES.CASHIER)}
              </MenuItem>
              <MenuItem value={RESTAURANT_ROLES.WAITER}>
                {getRoleName(RESTAURANT_ROLES.WAITER)}
              </MenuItem>
            </AppSelector>
          )}
        />

        <Controller
          name="backofficeAccess"
          control={control}
          render={({ field }) => (
            <FormControlLabel
              control={
                <Checkbox
                  checked={field.value}
                  onChange={(e) => field.onChange(e.target.checked)}
                  color="primary"
                />
              }
              label="Back office access"
              sx={{
                margin: '-10px -10px 5px',
                '& .MuiTypography-body1': {
                  fontSize: '0.9rem',
                },
              }}
            />
          )}
        />

        {backofficeAccess && (
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <AppTextInput
                label="Email Address"
                placeholder="youremail@example.com"
                {...field}
                error={errors.email?.message}
              />
            )}
          />
        )}

        {!backofficeAccess && (
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <AppTextInput
                label="Name"
                placeholder="Employee Name"
                {...field}
                error={errors.name?.message}
                touched={true}
              />
            )}
          />
        )}

        {!backofficeAccess && (
          <Controller
            name="phoneNumber"
            control={control}
            render={({ field }) => (
              <AppPhoneInput
                label="Phone Number"
                placeholder="0000 0000"
                value={field.value}
                onChange={(value) =>
                  setValue('phoneNumber', value, {
                    shouldValidate: true,
                  })
                }
                error={errors.phoneNumber?.message}
                onBlur={field.onBlur}
              />
            )}
          />
        )}

        <Controller
          name="posAccess"
          control={control}
          render={({ field }) => (
            <FormControlLabel
              control={
                <Checkbox
                  checked={field.value}
                  onChange={(e) => {
                    field.onChange(e.target.checked);
                    setShowPinFields(e.target.checked);
                  }}
                  color="primary"
                  disabled={!backofficeAccess}
                />
              }
              label="POS access"
              sx={{
                margin: '-10px -10px 5px',
                '& .MuiTypography-body1': {
                  fontSize: '0.9rem',
                },
              }}
            />
          )}
        />
        {showPinFields && (
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              PIN
            </Typography>
            <PinField
              length={4}
              ref={pinFieldRef}
              onChange={handlePinChange}
              onComplete={handlePinComplete}
              validate="/^[0-1-2-3-4-5-6-7-8-9]$/"
              style={{
                margin: '0 5px',
                textAlign: 'center',
                fontSize: '1rem',
                width: '3em',
                height: '3.5em',
                borderRadius: '8px',
                border: '1px solid #d3d3d3',
              }}
            />
            {errors.pin && typeof errors.pin !== 'undefined' && (
              <Typography
                color="error"
                variant="caption"
                display="block"
                textAlign="left">
                {Object.values(errors.pin).some((error) => error !== undefined)
                  ? 'PIN must be 4 digits.'
                  : ''}
              </Typography>
            )}
            {pinError && (
              <Typography
                color="error"
                variant="caption"
                display="block"
                textAlign="left">
                {pinError}
              </Typography>
            )}
          </Box>
        )}
      </AppPopup>
    </form>
  );
};

export default CreateEmployeePopup;
