import { useCallback, useState } from 'react';

type SortOrder = 'asc' | 'desc' | undefined;

interface useTableSortingProps {
  initialColumn: string;
  initialOrder: 'asc' | 'desc' | undefined;
  resetPagination: () => void;
}

export const useTableSorting = ({
  initialColumn,
  initialOrder,
  resetPagination,
}: useTableSortingProps) => {
  const [sortOrder, setSortOrder] = useState<SortOrder>(initialOrder);
  const [sortColumn, setSortColumn] = useState<string>(initialColumn);

  const onPressSortColumn = useCallback(
    (pressedColumn: string) => {
      if (pressedColumn === sortColumn) {
        setSortOrder((prevSortOrder) => {
          if (prevSortOrder === 'asc') {
            return 'desc';
          } else if (prevSortOrder === 'desc') {
            return undefined; // this represents no sort
          } else {
            return 'asc';
          }
        });
      } else {
        setSortColumn(pressedColumn);
        setSortOrder('asc'); // default to ascending when changing column
      }
      resetPagination(); // Reset pagination every time sorting is changed
    },
    [sortColumn, resetPagination],
  );

  return { sortColumn, sortOrder, onPressSortColumn };
};
