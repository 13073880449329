import {
  APP_TABLE_ALIGNMENT,
  IAppTableColumn,
} from '../../../components/AppTable/types/table.types';

export const EMPLOYEES_COLUMNS: IAppTableColumn[] = [
  {
    accessorKey: 'name',
    header: 'EMPLOYEE',
    headerAlign: APP_TABLE_ALIGNMENT.LEFT,
    bodyAlign: APP_TABLE_ALIGNMENT.LEFT,
    category: 'Defaults',
    visible: true,
    visibilityChangeable: true,
    bodySx: {
      minWidth: '50px',
    },
    enableSorting: true,
  },
  {
    accessorKey: 'email',
    header: 'EMAIL',
    headerAlign: APP_TABLE_ALIGNMENT.LEFT,
    bodyAlign: APP_TABLE_ALIGNMENT.LEFT,
    category: 'Defaults',
    visible: true,
    visibilityChangeable: true,
    bodySx: {
      minWidth: '50px',
    },
    enableSorting: true,
  },
  {
    accessorKey: 'role',
    header: 'ROLE',
    headerAlign: APP_TABLE_ALIGNMENT.LEFT,
    bodyAlign: APP_TABLE_ALIGNMENT.LEFT,
    category: 'Defaults',
    visible: true,
    visibilityChangeable: true,
    bodySx: {
      minWidth: '50px',
    },
  },
  {
    accessorKey: 'mobile',
    header: 'PHONE NUMBER',
    headerAlign: APP_TABLE_ALIGNMENT.LEFT,
    bodyAlign: APP_TABLE_ALIGNMENT.LEFT,
    category: 'Defaults',
    visible: true,
    visibilityChangeable: true,
    bodySx: {
      minWidth: '50px',
    },
  },
  {
    accessorKey: 'status',
    header: 'STATUS',
    headerAlign: APP_TABLE_ALIGNMENT.CENTER,
    bodyAlign: APP_TABLE_ALIGNMENT.CENTER,
    category: 'Defaults',
    visible: true,
    visibilityChangeable: true,
    bodySx: {
      minWidth: '50px',
    },
    enableSorting: true,
  },
  {
    accessorKey: 'lastActiveAt',
    header: 'LAST LOGGED IN',
    headerAlign: APP_TABLE_ALIGNMENT.CENTER,
    bodyAlign: APP_TABLE_ALIGNMENT.CENTER,
    category: 'Defaults',
    visible: true,
    visibilityChangeable: false,
    enableSorting: true,
  },
];

export enum EMPLOYEE_ACTION_KEYS {
  CANCEL = 'CANCEL',
  SUBMIT = 'SUBMIT',
}
