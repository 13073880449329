import {
  APP_COLUMN_TYPES,
  IAppTableRow,
} from '../../../components/AppTable/types/table.types';
import { EMPLOYEE_STATUS } from '../../../constants';
import { Colors } from '../../../theme/colors';
import { getRoleName } from '../../../utils';
import { ACTION_KEYS } from '../../menu/constants';
import {
  IEmployeesResponseDTO,
  IEmployeesTable,
} from '../types/employees.types';

export const getFormattedEmployees = (employee: IEmployeesResponseDTO) => {
  const row: IAppTableRow<IEmployeesTable> = {
    key: employee.empResRoleId
      ? employee.empResRoleId.toString()
      : employee.empId.toString(),
    name: {
      type: APP_COLUMN_TYPES.TEXT,

      data: {
        value: employee.name || '-',
      },
    },
    email: {
      type: APP_COLUMN_TYPES.TEXT,
      data: {
        value: employee.email || '-',
      },
    },
    role: {
      type: APP_COLUMN_TYPES.TEXT,
      data: {
        value: getRoleName(employee.role) || '-',
      },
    },
    mobile: {
      type: APP_COLUMN_TYPES.TEXT,
      data: {
        value: employee.mobile || '-',
      },
    },

    status: {
      type: APP_COLUMN_TYPES.CHIP,
      data: {
        value: getStatusText(employee.status),
        ...getStatusChipData(employee.status),
      },
    },
    lastActiveAt: {
      type: APP_COLUMN_TYPES.DATE,
      data: {
        value: `${employee?.lastActiveAt ?? '-'}`,
        disableLocalTime: true,
      },
    },
    subRows: [],
    actions: [],
  };

  if (employee.status !== EMPLOYEE_STATUS.PENDING) {
    row.actions.push({
      key: ACTION_KEYS.EDIT,
      name: 'Edit',
    });
  }

  switch (employee.status) {
    case EMPLOYEE_STATUS.ACTIVE:
      row.actions.push({
        key: ACTION_KEYS.DEACTIVATE,
        name: 'Deactivate',
      });
      break;
    case EMPLOYEE_STATUS.INACTIVE:
      row.actions.push({
        key: ACTION_KEYS.ACTIVATE,
        name: 'Activate',
      });
      break;
    default:
      break;
  }

  row.actions.push({
    key: ACTION_KEYS.DELETE,
    name: 'Delete',
    fontColor: Colors.error,
  });

  return row;
};

const getStatusChipData = (
  value: string,
): {
  color?: string;
  backgroundColor?: string;
} => {
  switch (value) {
    case EMPLOYEE_STATUS.ACTIVE:
      return {
        backgroundColor: Colors.chipSuccessBackground,
        color: Colors.chipSuccessText,
      };

    case EMPLOYEE_STATUS.INACTIVE:
      return {
        backgroundColor: Colors.backgroundShade,
        color: Colors.chipDefaultText,
      };

    default:
      return {
        backgroundColor: Colors.chipDefaultBackground,
        color: Colors.chipDefaultText,
      };
  }
};

const getStatusText = (status: string): string => {
  switch (status) {
    case EMPLOYEE_STATUS.ACTIVE:
      return 'Active';
    case EMPLOYEE_STATUS.INACTIVE:
      return 'Inactive';
    case EMPLOYEE_STATUS.PENDING:
      return 'Pending';
    default:
      return 'Unknown';
  }
};
