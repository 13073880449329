import { yupResolver } from '@hookform/resolvers/yup';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import PinField from 'react-pin-field';
import { boolean, object, ObjectSchema, string } from 'yup';
import * as yup from 'yup';

import AppButton from '../../../components/AppButton';
import { useConfirmationPopup } from '../../../components/AppConfirmationPopup';
import AppPhoneInput from '../../../components/AppPhoneInput';
import AppPopup, { IDialogAction } from '../../../components/AppPopup';
import AppSelector from '../../../components/AppSelector';
import { SNACKBARTYPE, useSnackbar } from '../../../components/AppSnackbar';
import AppTextInput from '../../../components/AppTextInput';
import { RESTAURANT_ROLES } from '../../../constants';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { getRoleName } from '../../../utils';
import { EMPLOYEE_ACTION_KEYS } from '../constants';
import { fetchEmployeePin, updateEmployeesData } from '../redux/employeeSlice';
import {
  CreateEmployeesRequestBodyDTO,
  IEmployeesResponseDTO,
} from '../types/employees.types';
import ConfirmationMessage from './ConfirmationMessage';

type EditEmployeePopupProps = {
  editData?: IEmployeesResponseDTO;
  open: boolean;
  onClose: (shouldRefresh?: boolean) => void;
};

type IEditEmployeeForm = {
  name?: string;
  email?: string;
  role: string;
  backofficeAccess?: boolean;
  phoneNumber?: string;
  posAccess?: boolean;
  pin?: (string | undefined)[];
};

const validationSchema: ObjectSchema<IEditEmployeeForm> = object({
  backofficeAccess: boolean().default(false),
  name: yup
    .string()
    .trim()
    .test({
      name: 'conditional-required',
      test: function (value: string | undefined) {
        if (this.parent.backofficeAccess === false) {
          return !!value;
        }

        return true;
      },
      message: 'Please enter an employee name.',
    })
    .max(60, 'Character limit exceeded (60)'),
  email: yup
    .string()
    .test({
      name: 'conditional-required-email',
      test: function (value: string | undefined) {
        if (this.parent.backofficeAccess) {
          if (!value) {
            return this.createError({
              message: 'Please enter an email address.',
            });
          }
          // Perform regex check for email format
          if (!/^.+@.+\..+$/.test(value)) {
            return this.createError({ message: 'Invalid email format' });
          }
          // Use Yup's built-in email validation as an additional check
          try {
            yup.string().email().validateSync(value);
          } catch {
            return this.createError({ message: 'Invalid email format' });
          }
        }
        return true;
      },
      message: 'Please enter an email address.',
    })
    .email('Invalid email format'),
  role: string()
    .oneOf(Object.values(RESTAURANT_ROLES))
    .required('Please select a role.'),
  phoneNumber: yup
    .string()
    .phone(
      { strictValidation: true },
      'Please enter a valid mobile number after the country code',
    )
    .required('Phone number is required. Please fill the field'),
  posAccess: yup.boolean(),
  pin: yup
    .array()
    .of(yup.string())
    .test(
      'conditional-required-pin',
      'PIN must be exactly 4 digits',
      function (pinArray = []) {
        // Access the `hasPosPin` value from the context
        const hasPosPin = this.options.context?.hasPosPin;

        if (!hasPosPin && this.parent.posAccess) {
          // Ensure all items are strings and check length and pattern
          return (
            pinArray.length === 4 &&
            pinArray.every((pin) => /^\d$/.test(pin ?? ''))
          );
        }

        return true; // If `hasPosPin` is true, then PIN is not required
      },
    ),
});

const EditEmployeePopup = ({
  open,
  onClose,
  editData,
}: EditEmployeePopupProps) => {
  const { openSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const { refreshing } = useAppSelector((state) => state.employees);
  const { openConfirmationPopup } = useConfirmationPopup();
  const [hasBackOfficeAccess, setHasBackOfficeAccess] = useState(false);
  const [showPinFields, setShowPinFields] = useState<boolean>(true);
  const [pin, setPin] = useState('');
  const pinFieldRef = useRef<HTMLInputElement[]>([]);
  const [hasPosPin, setHasPosPin] = useState(false);
  const { user } = useAppSelector((state) => state.auth);

  const canManageEmployee = user?.role !== 'WAITER' && user?.role !== 'CASHIER';

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    reset,
    setValue,
    watch,
  } = useForm<IEditEmployeeForm>({
    resolver: yupResolver(validationSchema),
    context: { hasPosPin }, // Pass `hasPosPin` state to the form's context
    defaultValues: {
      name: '',
      email: '',
      role: RESTAURANT_ROLES.ADMINISTRATOR,
      backofficeAccess: false,
      phoneNumber: '',
      posAccess: false,
      pin: ['', '', '', ''],
    },
  });

  const currentBOAccess = watch('backofficeAccess');
  const showInvite =
    !editData?.email && currentBOAccess && !hasBackOfficeAccess;

  const regeneratePin = async () => {
    const actionResult = await dispatch(fetchEmployeePin());
    const newPinValue = actionResult.payload as string;
    const formattedPin = newPinValue.toString().padStart(4, '0');
    setPin(formattedPin);

    const pinDigits = formattedPin.split('');
    // Update the react-hook-form state with the new PIN
    if (pinFieldRef.current && pinFieldRef.current.length === 4) {
      pinFieldRef.current.forEach((input, index) => {
        if (input) {
          input.value = pinDigits[index];
          // update the react-hook-form state
          setValue(`pin.${index}`, pinDigits[index], {
            shouldValidate: true,
          });
        }
      });
    }
  };

  const onSubmit = handleSubmit(async (data) => {
    const employeeData: CreateEmployeesRequestBodyDTO = {
      isEnableBackOfficeAccess: data.backofficeAccess ?? false,
      isEnablePosAccess: data.posAccess ?? false,
      role: data.role,
      posPin: data.posAccess && pin ? pin : undefined, // Only include the PIN if POS access is true and a PIN is set.
    };

    // Include name and phone number only if backoffice access is false and not a registered user.
    if (!data.backofficeAccess && !hasBackOfficeAccess && !editData?.email) {
      employeeData.name = data.name;
      employeeData.mobile = data.phoneNumber;
    }
    // Include the email only if it's different from what the backend provided,
    if (editData?.email !== data.email) {
      employeeData.email = data.email;
    }

    if (editData) {
      const actionResult = await dispatch(
        updateEmployeesData({
          id: editData.empResRoleId.toString(),
          data: employeeData,
        }),
      );

      if (actionResult.meta.requestStatus === 'fulfilled') {
        openSnackbar(
          showInvite
            ? 'Invitation sent successfully!'
            : 'Employee updated successfully!',
          SNACKBARTYPE.SUCCESS,
        );
        onClose(true);
      } else {
        // Check if payload exists and has the 'message' property
        if (actionResult.payload && 'message' in actionResult.payload) {
          const errorMessage =
            actionResult.payload.message ?? 'Failed to update employee.';
          openSnackbar(errorMessage, SNACKBARTYPE.ERROR);

          if (actionResult.payload.isUserInOtherRestaurants) {
            openConfirmationPopup({
              heading: 'Error Updating Employee',
              content: <ConfirmationMessage />,
              confirmButtonTitle: 'Invite',
              cancelButtonTitle: 'Choose a different email',

              onConfirm: async () => {
                const updatedEmployeeData = {
                  // Update the employeeData with inviteToRestaurant set to true
                  ...employeeData,
                  inviteToRestaurant: true,
                };

                const response = await dispatch(
                  updateEmployeesData({
                    id: editData.empResRoleId.toString(),
                    data: updatedEmployeeData,
                  }),
                );
                if (response.meta.requestStatus === 'fulfilled') {
                  openSnackbar(
                    'Employee invited successfully!',
                    SNACKBARTYPE.SUCCESS,
                  );
                }

                onClose(true);
              },
              onCancel: () => {
                onClose(true);
              },
            });
          }
        }
      }
    }
  });

  useEffect(() => {
    if (editData && open) {
      setValue('name', editData.name);
      setValue('email', editData.email ?? undefined);
      setValue('phoneNumber', editData.mobile);
      if (editData.role === undefined) {
        setValue('role', RESTAURANT_ROLES.ADMINISTRATOR);
      } else {
        setValue('role', editData.role);
      }

      const productArray = editData.products
        ? editData.products.split(',')
        : [];
      const backOfficeAccess = productArray.includes('BACK_OFFICE');
      const hasPosAccess = productArray.includes('POS');

      setValue('backofficeAccess', backOfficeAccess);
      setValue('posAccess', hasPosAccess);
      setHasBackOfficeAccess(backOfficeAccess);
      setShowPinFields(hasPosAccess);
      setHasPosPin(editData.hasPosPin);
    }
  }, [editData, open, setValue, hasPosPin]);

  const actions: IDialogAction[] = [
    {
      key: EMPLOYEE_ACTION_KEYS.CANCEL,
      title: 'Cancel',
      onClick: () => {
        reset();
        onClose();
      },
      color: 'secondary',
    },
    {
      key: EMPLOYEE_ACTION_KEYS.SUBMIT,
      title: showInvite ? 'Invite' : 'Save',
      onClick: onSubmit,
      disabled: !canManageEmployee || isSubmitting,
      color: 'primary',
    },
  ];

  const handlePinChange = (code: string) => {
    const pinArray = code.split('');

    setValue('pin', pinArray, { shouldValidate: true });
  };

  const handlePinComplete = (code: string) => {
    setPin(code);
  };

  const showEmailField = hasBackOfficeAccess || currentBOAccess; // combine the two conditions to determine if the email field should be displayed

  return (
    <form onSubmit={onSubmit}>
      <AppPopup
        open={open}
        onClose={() => {}}
        loading={refreshing}
        actions={actions}
        fixedWidth="460px"
        title="Edit Employee">
        <Controller
          name="role"
          control={control}
          render={({ field: { onChange, value } }) => (
            <AppSelector
              label="Role"
              placeholder="Administrator"
              value={value}
              onChange={onChange}>
              <MenuItem value={RESTAURANT_ROLES.OWNER}>
                {getRoleName(RESTAURANT_ROLES.OWNER)}
              </MenuItem>
              <MenuItem value={RESTAURANT_ROLES.ADMINISTRATOR}>
                {getRoleName(RESTAURANT_ROLES.ADMINISTRATOR)}
              </MenuItem>
              <MenuItem value={RESTAURANT_ROLES.MANAGER}>
                {getRoleName(RESTAURANT_ROLES.MANAGER)}
              </MenuItem>
              <MenuItem value={RESTAURANT_ROLES.CASHIER}>
                {getRoleName(RESTAURANT_ROLES.CASHIER)}
              </MenuItem>
              <MenuItem value={RESTAURANT_ROLES.WAITER}>
                {getRoleName(RESTAURANT_ROLES.WAITER)}
              </MenuItem>
            </AppSelector>
          )}
        />

        <Controller
          name="backofficeAccess"
          control={control}
          render={({ field }) => (
            <FormControlLabel
              control={
                <Checkbox
                  checked={field.value}
                  onChange={(e) =>
                    setValue('backofficeAccess', e.target.checked)
                  }
                  color="primary"
                />
              }
              label="Back office access"
              sx={{
                margin: '-10px -10px 5px',
                '& .MuiTypography-body1': {
                  fontSize: '0.9rem',
                },
              }}
            />
          )}
        />

        {showEmailField && (
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <AppTextInput
                label="Email Address"
                placeholder="youremail@example.com"
                {...field}
                error={errors.email?.message}
                disabled={hasBackOfficeAccess}
              />
            )}
          />
        )}

        <div style={{ display: hasBackOfficeAccess ? 'none' : 'block' }}>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <AppTextInput
                label="Name"
                placeholder="Employee Name"
                {...field}
                error={errors.name?.message}
                disabled={!!editData?.email} // Disable if there's an email from the backend
              />
            )}
          />
        </div>

        <div style={{ display: hasBackOfficeAccess ? 'none' : 'block' }}>
          <Controller
            name="phoneNumber"
            control={control}
            render={({ field }) => (
              <AppPhoneInput
                label="Phone Number"
                placeholder="0000 0000"
                value={field.value}
                onChange={(value) => setValue('phoneNumber', value)}
                error={errors.phoneNumber?.message}
                onBlur={field.onBlur}
                disabled={!!editData?.email}
              />
            )}
          />
        </div>

        <Controller
          name="posAccess"
          control={control}
          render={({ field }) => (
            <FormControlLabel
              control={
                <Checkbox
                  checked={field.value}
                  onChange={(e) => {
                    field.onChange(e.target.checked); // Update form state
                    setShowPinFields(e.target.checked); // Update PIN fields visibility
                  }}
                  color="primary"
                />
              }
              label="POS access"
              sx={{
                margin: '-10px -10px 5px',
                '& .MuiTypography-body1': {
                  fontSize: '0.9rem',
                },
              }}
            />
          )}
        />

        {showPinFields && (
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>
              <Typography variant="subtitle1" gutterBottom>
                PIN
              </Typography>
              <PinField
                length={4}
                placeholder="•"
                ref={pinFieldRef}
                onChange={handlePinChange}
                onComplete={handlePinComplete}
                validate="/^[0-1-2-3-4-5-6-7-8-9]$/"
                style={{
                  margin: '0 5px',
                  textAlign: 'center',
                  fontSize: '1rem',
                  width: '3em',
                  height: '3.5em',
                  borderRadius: '8px',
                  border: '1px solid #d3d3d3',
                }}
              />
              {errors.pin && typeof errors.pin !== 'undefined' && (
                <Typography
                  color="error"
                  variant="caption"
                  display="block"
                  textAlign="left">
                  {Object.values(errors.pin).some(
                    (pinError) => pinError !== undefined,
                  )
                    ? 'PIN must be 4 digits.'
                    : ''}
                </Typography>
              )}
            </Box>
            <AppButton
              title="Regenerate"
              startIcon={<AutorenewIcon />}
              size="large"
              variant="outlined"
              onClick={regeneratePin}
              sx={{
                height: '3.5em',
                marginTop: '35px',
              }}
            />
          </Box>
        )}
      </AppPopup>
    </form>
  );
};

export default EditEmployeePopup;
