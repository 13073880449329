import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import AppButton from '../../../components/AppButton';

type Props = {
  title: string;
  buttonText: string;
  hideButton?: boolean;
  buttonDisabled?: boolean;
  onClickCreate: () => void;
  children: React.ReactNode;
};

function ViewLayout({
  title,
  buttonText,
  onClickCreate,
  children,
  hideButton,
  buttonDisabled,
}: Readonly<Props>) {
  return (
    <Box
      sx={{
        ml: -5,
      }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 2,
        }}>
        <Typography variant="h5" fontWeight={700}>
          {title}
        </Typography>

        {hideButton ? null : (
          <AppButton
            title={buttonText}
            variant="contained"
            onClick={onClickCreate}
            startIcon={<AddIcon />}
            size="medium"
            disabled={buttonDisabled}
            data-testid="view-layout-button"
          />
        )}
      </Box>
      {children}
    </Box>
  );
}

export default ViewLayout;
