import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import debounce from 'lodash/debounce';
import truncate from 'lodash/truncate';
import { useCallback, useEffect, useMemo, useState } from 'react';

import SearchIcon from '../../../assets/searchIcon.png';
import personApron from '../../../assets/svgs/person_apron.svg';
import { useConfirmationPopup } from '../../../components/AppConfirmationPopup';
import AppEmptyTable from '../../../components/AppEmptyTable';
import AppTable from '../../../components/AppTable';
import { IAppTableRow } from '../../../components/AppTable/types/table.types';
import AppTextInput from '../../../components/AppTextInput';
import { EMPLOYEE_STATUS } from '../../../constants';
import {
  useAppDispatch,
  useAppSelector,
  usePaginationState,
} from '../../../hooks';
import { useTableSorting } from '../../../hooks/useTableSorting';
import { ACTION_KEYS } from '../../menu/constants';
import ViewLayout from '../../menu/hocs/ViewLayout';
import CreateEmployeePopup from '../components/CreateEmployeePopup';
import EditEmployeePopup from '../components/EditEmployeePopup';
import { EMPLOYEES_COLUMNS } from '../constants/index';
import {
  setEmployeePaginationLimit,
  setEmployeePaginationSkip,
} from '../redux/employeePaginationSlice';
import {
  fetchEmployees,
  getSingleEmployee,
  patchUpdateEmployeesData,
} from '../redux/employeeSlice';
import {
  IEmployeesResponseDTO,
  IEmployeesTable,
} from '../types/employees.types';
import { getFormattedEmployees } from '../utils/formatting.utils';

const EmployeesPage = () => {
  const dispatch = useAppDispatch();
  const { employeesData, refreshing, extras } = useAppSelector(
    (state) => state.employees,
  );
  const { user } = useAppSelector((state) => state.auth);

  const canManageEmployee = user?.role !== 'WAITER' && user?.role !== 'CASHIER';

  const [isCreatePopupOpen, setIsCreatePopupOpen] = useState(false);
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
  const [editData, setEditData] = useState<IEmployeesResponseDTO>();

  const { limit } = useAppSelector((state) => state.employeePagination);
  const { openConfirmationPopup } = useConfirmationPopup();
  const [searchTerm, setSearchTerm] = useState('');

  const { onChange, pageIndex, pageSize, total } = usePaginationState({
    total: extras.total,
    pageSize: extras.limit ?? 10,
    pageIndex:
      (extras.skip ?? 0) > 0
        ? Math.floor((extras.skip ?? 0) / (extras.limit ?? 0))
        : 0,
  });

  const resetPagination = useCallback(() => {
    dispatch(setEmployeePaginationSkip(0)); // Reset skip value to 0
    onChange(0); // Reset to the first page
  }, [dispatch, onChange]);

  const { sortColumn, sortOrder, onPressSortColumn } = useTableSorting({
    initialColumn: 'empResRoleId',
    initialOrder: undefined,
    resetPagination,
  });

  const debouncedSearch = useMemo(() => {
    return debounce(
      (searchKey: string) => {
        const skipValue = pageIndex * pageSize;
        dispatch(setEmployeePaginationSkip(skipValue));
        dispatch(setEmployeePaginationLimit(pageSize));
        dispatch(
          fetchEmployees({
            limit,
            skip: skipValue,
            search_key: searchKey,
            sort_by: sortColumn,
            sort_order: sortOrder,
          }),
        );
      },
      500,
      { leading: false, trailing: true },
    );
  }, [dispatch, limit, pageSize, pageIndex, sortColumn, sortOrder]);

  useEffect(() => {
    debouncedSearch(searchTerm);

    return () => debouncedSearch.cancel();
  }, [searchTerm, debouncedSearch]);

  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSearchTerm(event.target.value.trim());
    onChange(0); // Reset to the first page whenever a new search is initiated
  };

  const handlePreviousClick = () => onChange(pageIndex - 1);
  const handleNextClick = () => onChange(pageIndex + 1);

  const formattedEmployeeData = useMemo(
    () =>
      Array.isArray(employeesData)
        ? employeesData.map((employee) => getFormattedEmployees(employee))
        : [],
    [employeesData],
  );

  const handleClickCreate = () => {
    setIsCreatePopupOpen(true);
  };

  const handleOnCloseCreate = async (shouldRefresh?: boolean) => {
    setIsCreatePopupOpen(false);
    if (shouldRefresh) {
      await debouncedSearch(searchTerm);
    }
  };

  const handleOnCloseEdit = async (shouldRefresh?: boolean) => {
    setIsEditPopupOpen(false);
    setTimeout(() => {
      setEditData(undefined);
    }, 500);
    if (shouldRefresh) {
      await debouncedSearch(searchTerm);
    }
  };

  const handleOnClickAction = async (
    key: string,
    data: IAppTableRow<IEmployeesTable>,
  ) => {
    const foundItem = employeesData.find(
      (item) => item.empResRoleId.toString() === data.key,
    );
    switch (key) {
      case ACTION_KEYS.ACTIVATE:
        openConfirmationPopup({
          heading: `Activate ${truncate(foundItem?.name, {
            length: 20,
          })}`,
          content: (
            <Box>
              <Typography component="span" sx={{ fontWeight: 600 }}>
                {truncate(foundItem?.name, {
                  length: 20,
                })}
              </Typography>
              {'  will be activated and will be able to access this account.'}
            </Box>
          ),
          confirmButtonTitle: 'Activate',
          onConfirm: async () => {
            if (foundItem) {
              await dispatch(
                patchUpdateEmployeesData({
                  id: foundItem.empResRoleId.toString(),
                  data: {
                    status: EMPLOYEE_STATUS.ACTIVE,
                  },
                }),
              );
            }

            await debouncedSearch(searchTerm);
          },
        });
        break;

      case ACTION_KEYS.DELETE:
        openConfirmationPopup({
          color: 'error',
          heading: `Delete ${truncate(foundItem?.name, {
            length: 20,
          })}`,
          content: (
            <Box>
              <Typography component="span" sx={{ fontWeight: 600 }}>
                {truncate(foundItem?.name, {
                  length: 20,
                })}
              </Typography>
              {
                ' will no longer be able to access this account and the data will not be retrievable'
              }
            </Box>
          ),
          confirmButtonTitle: 'Delete',
          onConfirm: async () => {
            if (foundItem) {
              await dispatch(
                patchUpdateEmployeesData({
                  id: foundItem.empResRoleId.toString(),
                  data: {
                    status: EMPLOYEE_STATUS.DELETED,
                  },
                }),
              );
            }

            await debouncedSearch(searchTerm);
          },
        });

        break;

      case ACTION_KEYS.DEACTIVATE:
        openConfirmationPopup({
          heading: `Deactivate ${truncate(foundItem?.name, {
            length: 20,
          })}`,
          content: (
            <Box>
              <Typography component="span" sx={{ fontWeight: 600 }}>
                {truncate(foundItem?.name, {
                  length: 20,
                })}
              </Typography>
              {
                ' will be deactivated and will no longer be available in Employees'
              }
            </Box>
          ),
          confirmButtonTitle: 'Deactivate',
          onConfirm: async () => {
            if (foundItem) {
              await dispatch(
                patchUpdateEmployeesData({
                  id: foundItem.empResRoleId.toString(),
                  data: {
                    status: EMPLOYEE_STATUS.INACTIVE,
                  },
                }),
              );
            }

            await debouncedSearch(searchTerm);
          },
        });

        break;

      case ACTION_KEYS.EDIT: {
        if (foundItem?.status !== EMPLOYEE_STATUS.PENDING) {
          handleOnClickRow(data);
        }
        break;
      }

      default:
        break;
    }
  };

  const handleOnClickRow = async (data: IAppTableRow<IEmployeesTable>) => {
    const response = await dispatch(getSingleEmployee(data.key));
    if (response.meta.requestStatus === 'fulfilled') {
      setEditData(response.payload as IEmployeesResponseDTO);
      setIsEditPopupOpen(true);
    }
  };

  return (
    <Box sx={{ marginTop: '25px', ml: 5 }}>
      <ViewLayout
        title=""
        buttonText="Create Employee"
        onClickCreate={handleClickCreate}
        buttonDisabled={!canManageEmployee}>
        <AppTextInput
          placeholder={'Search Employee...'}
          icon={<img src={SearchIcon} alt="search" />}
          sx={{ width: '347px', marginTop: '-70px', alignSelf: 'center' }}
          onChange={handleSearchInputChange}
        />

        <AppTable
          sortOrder={sortOrder}
          sortColumn={sortColumn}
          onPressSortColumn={onPressSortColumn}
          enableRowActions={canManageEmployee}
          refreshing={refreshing}
          columns={EMPLOYEES_COLUMNS}
          rows={formattedEmployeeData}
          onClickActions={handleOnClickAction}
          onClickRow={(data) => {
            const foundItem = employeesData.find(
              (item) => item.empResRoleId.toString() === data.key,
            );

            if (foundItem?.status === EMPLOYEE_STATUS.PENDING) {
              return;
            }

            handleOnClickRow(data);
          }}
          handleNextClick={handleNextClick}
          handlePreviousClick={handlePreviousClick}
          pagination={{
            total: total,
            pageIndex: pageIndex,
            pageSize: pageSize,
          }}
          emptyComponent={
            <AppEmptyTable
              title="No Employees Added"
              icon={<img src={personApron} alt="personApron" />}
            />
          }
        />

        {isCreatePopupOpen && (
          <CreateEmployeePopup
            open={isCreatePopupOpen}
            onClose={handleOnCloseCreate}
          />
        )}

        {isEditPopupOpen && editData && (
          <EditEmployeePopup
            editData={editData}
            open={isEditPopupOpen}
            onClose={handleOnCloseEdit}
          />
        )}
      </ViewLayout>
    </Box>
  );
};

export default EmployeesPage;
