import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';

import { Colors } from '../theme/colors';
import AppButton from './AppButton';

export interface IDialogAction {
  type?: 'submit' | 'button';
  key: string;
  title: string;
  color: 'primary' | 'secondary';
  onClick: () => void;
  disabled?: boolean;
}

interface PopupProps {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  actions?: IDialogAction[];
  title?: string;
  loading?: boolean;
  fixedHeight?: string;
  fixedWidth?: string;
  disableBackdrop?: boolean;
}

function AppPopup({
  open,
  onClose,
  children,
  actions,
  title,
  loading,
  fixedHeight,
  fixedWidth,
  disableBackdrop = false,
}: Readonly<PopupProps>) {
  const handleCloseWithBackDropException = (
    _event: React.MouseEvent,
    reason: string,
  ) => {
    if (reason !== 'backdropClick') {
      onClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={disableBackdrop ? handleCloseWithBackDropException : onClose}
      PaperProps={{
        sx: {
          minWidth: fixedWidth ? fixedWidth : '400px',
          borderRadius: '8px',
          height: fixedHeight ? fixedHeight : 'auto',
        },
      }}>
      {title ? (
        <DialogTitle
          style={{
            marginTop: '10px',
            textDecoration: 'none',
            fontSize: '24px',
            fontWeight: 600,
            textAlign: 'left',
            color: Colors.fontColorPrimary,
          }}>
          {title}
        </DialogTitle>
      ) : undefined}
      <DialogContent
        sx={{
          opacity: loading ? 0.5 : 1,
          '&::-webkit-scrollbar': {
            width: '5px',
          },
        }}>
        {children}
        <Backdrop
          open={!!loading}
          sx={{
            position: 'absolute',
            zIndex: 999,
            backgroundColor: 'transparent',
          }}>
          <CircularProgress size="25px" thickness={5} />
        </Backdrop>
      </DialogContent>
      {actions && actions.length > 0 ? (
        <>
          <Divider />
          <DialogActions sx={{ px: 3, py: 2 }}>
            {actions.map((action) => (
              <AppButton
                type={action.type}
                key={action.key}
                onClick={action.onClick}
                disabled={action.disabled}
                variant="contained"
                color={action.color}
                title={action.title}
              />
            ))}
          </DialogActions>
        </>
      ) : undefined}
    </Dialog>
  );
}

export default AppPopup;
